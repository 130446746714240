import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { TdbQuill as RealComponent } from '../LazyLoader';

export default class TdbQuill extends Component {
    render() {
        return (
            <React.Suspense fallback={null}>
                <RealComponent {...this.props}/>
            </React.Suspense>
        );
    }
}

TdbQuill.defaultProps = {
    //hasToolbar: true,
    maxLength: 140,
    modules: {
        toolbar: [
        [{ 'font': [] }],
        [{size: []}],
        [{ 'color': [] }, { 'background': [] }],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link', 'image'],
        ['clean']
        ],  
        clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
        }
    },
};

TdbQuill.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * A label that will be printed when this component is rendered.
     */
    // label: PropTypes.string.isRequired,

    /**
     * The value displayed in the input.
     */
    value: PropTypes.string,

    /**
      * The value displayed in the input.
      */
    maxLength: PropTypes.number,

    /**
     * The number of charaters in the editor (excl HTML)
     */
    charCount: PropTypes.number,

    /**
     * The toolbar options modules.
     * Should be {'toolbar':[list of options]}
     */
     modules: PropTypes.object,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};


export const defaultProps = TdbQuill.defaultProps;
export const propTypes = TdbQuill.propTypes;